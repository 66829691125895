import React from "react";

import ServerGuide, {
    GeneralCancelCommandNotes,
    GeneralUsingNativeApiNotes,
    GeneralNativeConnectionHandles,
    GeneralNativeCommandHandles
} from "../../components/server-guide";
import {options} from "../../data/sqlbase";
import {Callout} from "../../components/alert";
import {ApiLink} from "../../components/link";

const ConnectApiNotes = () => (
    <Callout heading="Minimum Version">
        SQLAPI++ library requires CAPI version 6.x or higher.
    </Callout>
);
const sDBString = () => (
    <p>
        The database name as it is specified in SQL.INI file
    </p>
);

const UserID = () => (<p>A string containing a user name to use when establishing the connection.</p>);

const OutputParamNotes = () => (
    <>
        <p>
            If SQLBase stored procedure returns only one row of output parameters
            (the most common case), you can get output parameters' values immediately after
            calling {' '}<ApiLink>SACommand::Execute</ApiLink>{' '} using appropriate
            {' '}<ApiLink>SAParam</ApiLink>{' '} objects.
        </p>
        <p>
            SQLBase stored procedures can also return several rows of output parameters.
            In this case SQLBase returns them as a result set and you can fetch them row by
            row using {' '}<ApiLink>SACommand::FetchNext</ApiLink>{' '} method after calling
            {' '}<ApiLink>SACommand::Execute</ApiLink>{' '} method.
        </p>
    </>
);

const ErrorNotes = () => (
    <p>
        In SQLBase {' '}<ApiLink>SAException::ErrPos</ApiLink>{' '} method returns character position of the syntax
        error within an SQL statement. The first character is position 0.
    </p>
);

const Api6ConnectionHandles = () =>
    <p>
        Note that appropriate type casting depends on an SQLBase API version. That generally mean that you have to
        explicitly check client version before casting,
        see {' '}<ApiLink>SAConnection::ClientVersion</ApiLink>{' '} method. If you are using older CAPI v6 client
        library, you need to cast to {' '}<code>sb6ConnectionHandles</code>, as connection handles are different in
        version 6:
    </p>;

const Page = () => (
    <ServerGuide server="SQLBase"
                 connectApiNotes={ConnectApiNotes}
                 sDBString={sDBString}
                 sUserID={UserID}
                 outputParamNotes={OutputParamNotes}
                 cancelCommandNotes={() => <GeneralCancelCommandNotes server="SQLBase"
                                                                      nativeCommand="sqlcan"/>}
                 options={options}
                 usingNativeApiNotes={() => <GeneralUsingNativeApiNotes server="SQLBase"
                                                                        cppHeader="sbAPI.h"
                                                                        apiClass="sbAPI"/>}
                 nativeConnectionHandles={() => <GeneralNativeConnectionHandles server="SQLBase"
                                                                                handleClass="sb7ConnectionHandles"
                                                                                legacy={{
                                                                                    handleClass: "sb6ConnectionHandles",
                                                                                    legacyInto: Api6ConnectionHandles
                                                                                }}
                                                                                cppHeader="sbAPI.h"/>}
                 nativeCommandHandles={() => <GeneralNativeCommandHandles server="SQLBase"
                                                                          handleClass="sbCommandHandles"
                                                                          cppHeader="sbAPI.h"/>}
                 errorNotes={ErrorNotes}
    />
);

export default Page;
