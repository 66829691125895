import React from "react";
import {CodeBlend} from "../components/code";

const options = [
    {
        name: 'SQLBASE.LIBS',
        scope: 'api',
        description: `Forces SQLAPI++ Library to use specified SQLBase client library.`,
        valid:
            <>
                Any valid SQLBase client library name list. Names separated by ';' on
                Windows or ':' on other operating systems.
            </>,
        defaultValue:
            <ul>
                <li>Windows &ndash; {' '}<code>"sqlwntm.dll"</code></li>
                <li>Linux &ndash; {' '}<code>"libsqlbapl.so"</code></li>
            </ul>
    },
    {
        name: 'SQLPPCX',
        scope: 'command',
        description: `Controls cursor-context preservation. For more information see SQLBase documentation.`,
        valid:
            <>
                <code>"off"</code>, {' '}<code>"on"</code>
            </>,
        defaultValue:
            <>
                see SQLBase documentation.
            </>
    },
    {
        name: 'StoredObject',
        scope: 'command',
        description:
            <>
                If you call stored command you can set this option to {' '}<code>"Command"</code>{' '} value.
                It allows SQLAPI++ to optimize stored command execution.
            </>,
        valid:
            <>
                <code>"Procedure"</code>, {' '}<code>"Command"</code>
            </>,
        defaultValue:
            <code>
                "Procedure"
            </code>
    },
    {
        name: 'UseDynamicCursor, Scrollable',
        scope: 'command',
        description:
            <>
                Configures SQLAPI++ to use SQLBase scrollable result set mode.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue:
            <code>"False"</code>
    },
    {
        name: 'SQLBaseUseDoubleForDecimal',
        scope: 'command',
        description:
            <>
                Configures SQLAPI++ to use {' '}<CodeBlend>SA_dtDouble</CodeBlend>{' '} type for SQLBase
                {' '}<CodeBlend>DECIMAL(x,y)</CodeBlend>{' '} data type.
            </>,
        valid:
            <>
                <code>"True"</code>, {' '}<code>"1"</code>
            </>,
        defaultValue:
            <code>"False"</code>
    }
];

export {
    options
};
